import React, { Component } from 'react'
import PropTypes from 'prop-types'

import style from './style.module.scss'
import { ProjectGridTriangles } from './ProjectGridTriangles'
import multiClass from '../../helpers/multiClass'

class ProjectGrid extends Component {
  state = {}

  render() {
    const { photosFirstRow, photosSecondRow } = this.props

    return (
      <div className={style.projectGridContainer}>
        <ProjectGridTriangles />
        <div className={style.gridContainer}>
          <section className={style.grid}>
            {photosFirstRow.map(photo => (
              <div
                key={photo.id}
                style={{
                  backgroundImage: `url(${photo.image})`,
                  backgroundRepeat: 'no-repeat'
                }}
                className={multiClass(style.gridItem, style[photo.className])}
              />
            ))}
          </section>
          {photosSecondRow.length > 0 && (
            <section className={style.gridSecond}>
              {photosSecondRow.map(photo => (
                <div
                  key={photo.id}
                  style={{
                    backgroundImage: `url(${photo.image})`,
                    backgroundRepeat: 'no-repeat'
                  }}
                  className={multiClass(style.gridItem, style[photo.className])}
                />
              ))}
            </section>
          )}
        </div>
      </div>
    )
  }
}

ProjectGrid.propTypes = {
  photosFirstRow: PropTypes.arrayOf(PropTypes.object),
  photosSecondRow: PropTypes.arrayOf(PropTypes.object)
}

ProjectGrid.defaultProps = {
  photosFirstRow: [],
  photosSecondRow: []
}

export default ProjectGrid
