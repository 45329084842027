import React from 'react'

const ProjectBlueprintTriangles = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="104"
    height="208"
    viewBox="0 0 104 208"
  >
    <g fill="none" fillRule="nonzero" opacity=".5">
      <path fill="#EFEFEF" d="M104 0v104L52 52z" />
      <path fill="#B9BFC8" d="M0 104V0l52 52z" />
      <path fill="#EFEFEF" d="M104 208H0l104-104z" />
    </g>
  </svg>
)

export { ProjectBlueprintTriangles }
