import React, { Component } from 'react'
import PropTypes from 'prop-types'

import style from './style.module.scss'
import { ProjectDescriptionTriangles } from './ProjectDescriptionTriangles'
import { ProjectDescriptionResponsiveTriangles } from './ProjectDescriptionResponsiveTriangles'

class ProjectDescription extends Component {
  state = {}

  scrollDown = () => {
    window.scrollTo(0, document.body.scrollHeight)
  }

  render() {
    const {
      typeService,
      duration,
      deliverDate,
      local,
      localShortened,
      description,
      color
    } = this.props

    return (
      <div className={style.projectDescriptionContainer}>
        <div className={style.boxesContainer}>
          <div
            className={style.projectService}
            style={{ backgroundColor: color }}
          >
            <p>{typeService}</p>
          </div>
          <div className={style.projectDuration}>
            <p className={style.label} style={{ color }}>
              Duração
            </p>
            <p className={style.value}>{duration}</p>
          </div>
          <div className={style.projectDeliver}>
            <p className={style.label} style={{ color }}>
              Entrega
            </p>
            <p className={style.value}>{deliverDate}</p>
          </div>
          <div className={style.projectLocal}>
            <p className={style.label} style={{ color }}>
              Local
            </p>
            <p className={style.value}>{local}</p>
            <p className={style.valueShortened}>{localShortened || local}</p>
          </div>
          <ProjectDescriptionResponsiveTriangles />
        </div>
        <div className={style.descriptionContainer}>{description}</div>
        <ProjectDescriptionTriangles />
      </div>
    )
  }
}

ProjectDescription.propTypes = {
  typeService: PropTypes.string,
  duration: PropTypes.string,
  deliverDate: PropTypes.string,
  local: PropTypes.string,
  description: PropTypes.element,
  color: PropTypes.string
}

ProjectDescription.defaultProps = {
  typeService: '',
  duration: '',
  deliverDate: '',
  local: '',
  description: '',
  color: ''
}

export default ProjectDescription
