import React from 'react'

const ProjectDescriptionResponsiveTriangles = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="60"
    viewBox="0 0 120 60"
  >
    <g fill="none" fillRule="nonzero" opacity=".5">
      <path fill="#EFEFEF" d="M59.665 59.856H0L29.832 30z" />
      <path fill="#B9BFC8" d="M0 0h59.665L29.832 29.856z" />
      <path fill="#EFEFEF" d="M59.665 0h59.665L59.665 59.713z" />
    </g>
  </svg>
)

export { ProjectDescriptionResponsiveTriangles }
