import React from 'react'
import PropTypes from 'prop-types'

import style from './style.module.scss'
import { ProjectBlueprintTriangles } from './ProjectBlueprintTriangles'

const ProjectBlueprint = props => {
  const { blueprint, blueprintDescription } = props

  return (
    <div className={style.projectBlueprintContainer}>
      <ProjectBlueprintTriangles />
      <div className={style.descriptionBlueprintContainer}>
        {blueprintDescription}
      </div>
      {blueprint && <img src={blueprint} alt="Planta baixa" />}
    </div>
  )
}

ProjectBlueprint.propTypes = {
  blueprint: PropTypes.string,
  blueprintDescription: PropTypes.element
}

ProjectBlueprint.defaultProps = {
  blueprint: '',
  blueprintDescription: ''
}

export default ProjectBlueprint
