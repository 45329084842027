import React, { Fragment } from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ProjectHeader from '../components/ProjectHeader'
import ProjectDescription from '../components/ProjectDescription'
import ProjectGrid from '../components/ProjectGrid'
import ProjectBlueprint from '../components/ProjectBlueprint'
import ProjectNextLink from '../components/ProjectNextLink'
import InstagramFeed from '../components/InstagramFeed'
import ContactBoxes from '../components/ContactBoxes'
import Footer from '../components/Footer'

import headerRightBanner from '../images/projects/apartamento-214-n/header-right-banner.jpeg'
import projecPhoto1 from '../images/projects/apartamento-214-n/apt-template-1.jpg'
import projecPhoto2 from '../images/projects/apartamento-214-n/apt-template-2.jpg'
import projecPhoto3 from '../images/projects/apartamento-214-n/apt-template-3.jpg'
import projecPhoto4 from '../images/projects/apartamento-214-n/apt-template-4.jpg'
import projecPhoto5 from '../images/projects/apartamento-214-n/apt-template-5.jpg'
import projecPhoto6 from '../images/projects/apartamento-214-n/apt-template-6.jpg'
import projecPhoto7 from '../images/projects/apartamento-214-n/apt-template-7.jpg'

import '../styles/global.scss'

const dynamicInfos = {
  headerImage: headerRightBanner,
  title: 'Apartamento',
  subTitle: '214n',
  typeService: 'PROJETO + OBRA',
  duration: '10 meses',
  deliverDate: '2017',
  local: 'Brasília',
  color: '#ffbf68',
  description: (
    <Fragment>
      <p>
        Temos muito carinho por esse projeto de reformulação completa deste
        apartamento de 2 quartos, porque a{' '}
        <b>inspiração do projeto é o modernismo brasiliense</b>. Unimos
        funcionalidade com soluções racionais para evitar excessos sem abrir mão
        do conforto dos moradores.
      </p>
      <p>
        Nesse caso, também executamos a obra em sistema de{' '}
        <b>Administração Completa</b>, ou seja, os clientes entregam a chaves e
        recebem o apartamento pronto pra mudança. A obra aconteceu em 4 meses.
      </p>
    </Fragment>
  ),
  photosFirstRow: [
    { id: 'gap1', gap: true, className: 'dimensionGap' },
    { id: '01', image: projecPhoto1, className: 'dimensionOption1' },
    { id: '02', image: projecPhoto4, className: 'dimensionOption2' },
    { id: '03', image: projecPhoto3, className: 'dimensionOption3' },
    { id: '04', image: projecPhoto2, className: 'dimensionOption4' }
  ],
  photosSecondRow: [
    { id: '01', image: projecPhoto5, className: 'dimensionOption5' },
    { id: '02', image: projecPhoto6, className: 'dimensionOption5' },
    { id: '03', image: projecPhoto7, className: 'dimensionOption6' },
    { id: 'gap1', gap: true, className: 'dimensionGap' }
  ],
  blueprintDescription: (
    <p>
      Fizemos uma <b>remodelação drástica</b> de layout para atender às
      necessidades específicas dos clientes. Quase todas as paredes do
      apartamento foram abaixo e o novo layout fez muito{' '}
      <b>mais sentido para o dia-a-dia do casal</b>.
    </p>
  ),
  nextProject: 'casa-cond-verde'
}

const IndexPage = () => (
  <Layout>
    <SEO
      title="Apartamento 214 N"
      keywords={[`gatsby`, `application`, `react`]}
    />
    <ProjectHeader
      headerImage={dynamicInfos.headerImage}
      title={dynamicInfos.title}
      subTitle={dynamicInfos.subTitle}
    />
    <ProjectDescription
      typeService={dynamicInfos.typeService}
      duration={dynamicInfos.duration}
      deliverDate={dynamicInfos.deliverDate}
      local={dynamicInfos.local}
      description={dynamicInfos.description}
      color={dynamicInfos.color}
    />
    <ProjectGrid
      photosFirstRow={dynamicInfos.photosFirstRow}
      photosSecondRow={dynamicInfos.photosSecondRow}
    />
    <ProjectBlueprint
      blueprint={dynamicInfos.blueprint}
      blueprintDescription={dynamicInfos.blueprintDescription}
    />
    <ProjectNextLink nextProject={dynamicInfos.nextProject} />
    <InstagramFeed mobileClassName="projectPage" />
    <ContactBoxes />
    <Footer />
  </Layout>
)

export default IndexPage
