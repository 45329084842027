import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { InstagramIcon, YoutubeIcon } from '../SocialIcons'
import multiClass from '../../helpers/multiClass'

import style from './style.module.scss'
import logo from '../../images/logo-m4.svg'
import templateImage from '../../images/template-project.png'
import { TriangleProjectHeader } from './ProjectHeaderTriangles'
import MobileMenu from '../MobileMenu'

class ProjectHeader extends Component {
  state = {}

  scrollDown = () => {
    window.scrollTo(0, document.body.scrollHeight)
  }

  render() {
    const { headerImage, title, subTitle } = this.props

    return (
      <div className={style.projectHeaderContainer}>
        <Link to="/" className={style.logom4}>
          <img src={logo} alt="Logo Studio M4" />
        </Link>
        <a
          className={multiClass(style.socialLink, style.instagramLink)}
          href="https://www.instagram.com/studiom4"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon />
        </a>
        <a
          href="https://www.youtube.com/channel/UCeyH-g2_EXMFpGlTM9v6ZJg"
          className={multiClass(style.socialLink, style.youtubeLink)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <YoutubeIcon />
        </a>
        <div className={style.menuContainer}>
          <Link to="../#contato" className={style.headerLink}>
            CONTATO
          </Link>
          {/* <Link to="/arquitetura-na-pratica" className={style.headerLink}>
            ARQUITETURA NA PRÁTICA
          </Link> */}
          <MobileMenu className={style.mobileMenu} contatctLink="/#contato" />
        </div>
        <div className={style.mainContainer}>
          <p>Portifólio</p>
          <div className={style.textContainer}>
            <h2 className={style.title}>
              <span>{title}</span>
            </h2>
            <h2 className={style.subTitle}>
              {subTitle && subTitle.split(' ').map(word => <span>{word}</span>)}
            </h2>
          </div>
          <div
            className={style.backgroundImage}
            style={{
              backgroundImage: `url(${headerImage})`
            }}
          />
          <TriangleProjectHeader />
        </div>
      </div>
    )
  }
}

ProjectHeader.propTypes = {
  headerImage: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string
}

ProjectHeader.defaultProps = {
  headerImage: templateImage,
  title: 'Título',
  subTitle: 'Subtítulo'
}

export default ProjectHeader
