import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import style from './style.module.scss'

import { RightArrow } from '../RightArrow'

const ProjectNextLink = props => {
  const { nextProject } = props

  return (
    <div className={style.projectNextLinkContainer}>
      <Link to={nextProject} className={style.nextLink}>
        <span>Próximo Projeto</span> <RightArrow />
      </Link>
    </div>
  )
}

ProjectNextLink.propTypes = {
  nextProject: PropTypes.string.isRequired
}

export default ProjectNextLink
