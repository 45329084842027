import React from 'react'

const ProjectDescriptionTriangles = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="209"
    height="210"
    viewBox="0 0 209 210"
  >
    <g fill="none" fillRule="nonzero" opacity=".5">
      <path fill="#EFEFEF" d="M106 0v104L54 52zM209 210H105l52-52z" />
      <path fill="#B9BFC8" d="M0 104V0l52 52zM105 105h104l-52 52z" />
      <path fill="#EFEFEF" d="M1 104h104L1 208z" />
    </g>
  </svg>
)

export { ProjectDescriptionTriangles }
