import React from 'react'

const TriangleProjectHeader = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="157"
    height="106"
    viewBox="0 0 157 106"
  >
    <g fill="none" fillRule="nonzero" opacity=".5">
      <path fill="#EFEFEF" d="M104 106H0l52-52z" />
      <path fill="#B9BFC8" d="M0 0h104L52 52z" />
      <path fill="#EFEFEF" d="M105 0h52l-52 52z" />
    </g>
  </svg>
)

export { TriangleProjectHeader }
